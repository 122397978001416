<template>
  <md-card>
    <md-card-header>
      <div class="md-layout md-alignment-center-left listing-table-header">
        <div class="md-layout-item md-size-20 table-header-item">
          <h1 class="md-title">Most Popular Sites</h1>
        </div>

        <div class="md-layout-item md-size-10 table-header-item">
          <md-field>
            <label for="itemsPerPage">Display</label>
            <md-select :value="itemsPerPage"
                       name="itemsPerPage"
                       id="itemsPerPage"
                       @md-selected="refreshList"
                       md-dense>
              <md-option value="25">25</md-option>
              <md-option value="50">50</md-option>
              <md-option value="100">100</md-option>
            </md-select>
          </md-field>
        </div>

        <div class="md-layout-item md-size-20 table-header-item">
          <md-field>
            <label for="sortBy">Sort by</label>
            <md-select :value="mostPopularSitesActiveSort.code"
                       name="sortBy"
                       id="sortBy"
                       @md-selected="sortFn"
                       md-dense>
              <md-option v-for="sortField in mostPopularSitesSortFields"
                         :key="sortField.code"
                         :value="sortField.code">
                {{ sortField.name }}
              </md-option>
            </md-select>
          </md-field>
        </div>

        <div class="md-layout-item md-size-30 table-header-item">
          <md-field :md-clearable="true" @md-clear="clearsearch">
            <label for="search">Search...</label>
            <md-input name="search" id="search"
                      :value="mostPopularSitesSearch"
                      @keyup="searchFn">
            </md-input>
          </md-field>
        </div>

        <div class="md-layout-item md-size-20 table-header-item">
          <md-button class="md-accent md-raised create-button"
                     @click="create">
            Create
          </md-button>
        </div>
      </div>
    </md-card-header>

    <md-card-content>
      <div class="md-layout">
        <div class="md-layout-item md-size-100">
          <listing-page :items="mostPopularSitesItems"
                        :singleRouteName="'adminMostPopularSiteSingle'"
                        :columns="columns"
                        @onRemove="onRemove"
                        :active="active">
          </listing-page>
        </div>
      </div>
    </md-card-content>

    <md-card-actions>
      <div class="md-layout">
        <div class="md-layout-item md-size-50">
          Showing {{ mostPopularSitesFrom }} to {{ mostPopularSitesTo }} of {{ mostPopularSitesTotal }} Most Popular
          Sites
        </div>
        <div class="md-layout-item md-size-50">
          <rec-paginator :current-page="mostPopularSitesCurrentPage"
                         :last-page="mostPopularSitesLastPage"
                         :threshold="2"
                         :has-prev-next="true"
                         :has-first-last="true"
                         @onFirstPage="onFirstPage"
                         @onPreviousPage="onPreviousPage"
                         @onNextPage="onNextPage"
                         @onLastPage="onLastPage"
                         @onChangePage="onChangePage">

          </rec-paginator>
        </div>
      </div>
      <MostPopularSiteModal @onClose="onClose" :active="active"></MostPopularSiteModal>
    </md-card-actions>
  </md-card>
</template>

<script>
import ListingPage from '@/components/page-templates/ListingPage.vue';
import RecPaginator from '@/components/pagination/RecPaginator.vue';
import MostPopularSiteModal from '@/components/modals/MostPopularSiteModal.vue';

import {
  FETCH_MOST_POPULAR_SITES,
  CLEAR_SEARCH_MOST_POPULAR_SITES,
  UPDATE_SEARCH_MOST_POPULAR_SITES,
  UPDATE_ACTIVE_SORT_FIELD_MOST_POPULAR_SITES,
  REMOVE_MOST_POPULAR_SITE,
} from '@/store/modules/admin/resources/mostPopularSites/mostPopularSitesActions.type';

export default {
  name: 'Sites',
  components: {
    ListingPage,
    RecPaginator,
    MostPopularSiteModal,
  },
  data: () => ({
    columns: [
      {
        name: 'address',
        label: 'Link',
      },
      {
        name: 'site_id',
        label: 'Site Id',
      },
      {
        name: 'iconpath',
        label: 'Image Path',
      },
      {
        name: 'position',
        label: 'Position',
      },
    ],
    active: false,
  }),
  computed: {
    mostPopularSitesItems() {
      return this.$store.getters.mostPopularSitesItems;
    },
    itemsPerPage() {
      return this.$store.getters.mostPopularSitesPerPage;
    },
    mostPopularSitesCurrentPage() {
      return this.$store.getters.mostPopularSitesCurrentPage;
    },
    mostPopularSitesLastPage() {
      return this.$store.getters.mostPopularSitesLastPage;
    },
    mostPopularSitesFrom() {
      return this.$store.getters.mostPopularSitesFrom;
    },
    mostPopularSitesTo() {
      return this.$store.getters.mostPopularSitesTo;
    },
    mostPopularSitesTotal() {
      return this.$store.getters.mostPopularSitesTotal;
    },
    mostPopularSitesSortFields() {
      return this.$store.getters.mostPopularSitesSortFields;
    },
    mostPopularSitesActiveSort() {
      return this.$store.getters.mostPopularSitesActiveSort;
    },
    mostPopularSitesSearch() {
      return this.$store.getters.mostPopularSitesSearch;
    },
  },
  mounted() {
    this.initMostPopularSites();
  },
  methods: {
    onClose() {
      this.active = false;
    },
    initMostPopularSites() {
      const params = {
        page: this.mostPopularSitesCurrentPage,
        perPage: this.itemsPerPage,
      };
      this.fetchMostPopularSites(params);
    },
    fetchMostPopularSites(params) {
      this.$store.dispatch(FETCH_MOST_POPULAR_SITES, params)
        .then((response) => {
          this.$log.info('FETCH_MOST_POPULAR_SITES', response);
        })
        .catch((error) => {
          this.$log.error('FETCH_MOST_POPULAR_SITES', error);
        });
    },
    onFirstPage() {
      const params = {
        page: 1,
        perPage: this.itemsPerPage,
      };
      this.fetchMostPopularSites(params);
    },
    onPreviousPage() {
      const params = {
        page: this.mostPopularSitesCurrentPage - 1,
        perPage: this.itemsPerPage,
      };
      this.fetchMostPopularSites(params);
    },
    onChangePage(data) {
      const params = {
        page: data,
        perPage: this.itemsPerPage,
      };
      this.fetchMostPopularSites(params);
    },
    onNextPage() {
      const params = {
        page: this.mostPopularSitesCurrentPage + 1,
        perPage: this.itemsPerPage,
      };
      this.fetchMostPopularSites(params);
    },
    onLastPage() {
      const params = {
        page: this.mostPopularSitesLastPage,
        perPage: this.itemsPerPage,
      };
      this.fetchSites(params);
    },
    refreshList(data) {
      const params = {
        page: 1,
        perPage: data,
      };
      this.fetchMostPopularSites(params);
    },
    clearsearch() {
      this.$store.dispatch(CLEAR_SEARCH_MOST_POPULAR_SITES)
        .then(() => {
          const params = {
            page: 1,
            perPage: this.itemsPerPage,
          };
          this.fetchMostPopularSites(params);
        })
        .catch((error) => {
          this.$log.error('CLEAR_SEARCH_MOST_POPULAR_SITES', error);
        });
    },
    searchFn(data) {
      if (data.key === 'Enter') {
        const searchValue = data.target.value;
        if (!searchValue || searchValue === '') {
          // clear search
          this.$store.dispatch(CLEAR_SEARCH_MOST_POPULAR_SITES)
            .then(() => {
              const params = {
                page: 1,
                perPage: this.itemsPerPage,
              };
              this.fetchMostPopularSites(params);
            })
            .catch((error) => {
              this.$log.error('CLEAR_SEARCH_MOST_POPULAR_SITES', error);
            });
        } else if (searchValue !== '') {
          this.$store.dispatch(UPDATE_SEARCH_MOST_POPULAR_SITES, searchValue)
            .then(() => {
              const params = {
                page: 1,
                perPage: this.itemsPerPage,
              };
              this.fetchMostPopularSites(params);
            })
            .catch((error) => {
              this.$log.error('UPDATE_SEARCH_MOST_POPULAR_SITES', error);
            });
        }
      }
    },
    sortFn(data) {
      this.$store.dispatch(UPDATE_ACTIVE_SORT_FIELD_MOST_POPULAR_SITES, data)
        .then(() => {
          const params = {
            page: 1,
            perPage: this.itemsPerPage,
          };
          this.fetchMostPopularSites(params);
        })
        .catch((error) => {
          this.$log.error('UPDATE_ACTIVE_SORT_FIELD_MOST_POPULAR_SITES', error);
        });
    },
    onRemove(item) {
      this.$store.dispatch(REMOVE_MOST_POPULAR_SITE, item)
        .then((response) => {
          this.$log.info('REMOVE_MOST_POPULAR_SITE', response);
          const params = {
            page: this.mostPopularSitesCurrentPage,
            perPage: this.itemsPerPage,
          };
          this.fetchMostPopularSites(params);
          this.$toasted.show(response.message, {
            type: 'success',
            duration: 5000,
            icon: 'check',
          });
        })
        .catch((error) => {
          this.$log.error('REMOVE_MOST_POPULAR_SITE', error);
          this.$toasted.error(error.message, {
            type: 'error',
            duration: 5000,
            icon: 'alert',
          });
        });
    },
    create() {
      this.active = true;
    },
  },
};
</script>

<style lang="scss">
.listing-table-header {
  .table-header-item {
    padding: 0.5rem;
    .md-field {
      margin: 0;
    }
    .create-button {
      float: right;
    }
  }
}

.md-card-actions, .md-card-content, .md-card-header {
  padding: 1rem;
}
</style>
